<template>
  <BaseModal
    card-width="480px"
    no-padding
    no-toolbar
    no-toolbar-padding
    no-exit
  >
    <div class="w-full h-full">
      <BaseProgressStepBar
        :steps="steps"
        :current-step="currentStep"
        class="border-b border-border-normal"
      />
      <!-- Steps -->
      <CreateLensSpace
        v-if="currentStep === 0"
        :form.sync="lensForm.space"
      />
      <ConnectAdAccount
        v-else-if="currentStep === 1"
      />
      <ConnectionSettings
        v-else-if="currentStep === 2"
        :form.sync="lensForm.connection"
      />
      <SetupBenchmarks
        v-else-if="currentStep === 3"
        :form.sync="lensForm.benchmark"
      />

      <!-- Bottom Step Navigation Buttoms -->
      <div class="mt-auto flex items-center justify-between p-5 border-t border-border-normal">
        <BaseButton
          outlined
          @click="handleCancel"
        >
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            Cancel
          </BaseText>
        </BaseButton>

        <div class="flex items-center justify-end gap-3">
          <BaseButton
            outlined
            :disabled="currentStep === 0"
            @click="handleBack"
          >
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              Back
            </BaseText>
          </BaseButton>
          <BaseButton
            primary
            @click="handleNext"
          >
            <BaseText
              type="label"
              size="sm"
            >
              {{ isLastStep ? 'Finish' : 'Next' }}
            </BaseText>
          </BaseButton>
        </div>
      </div>
    </div>
  </BaseModal>
</template>
<script>
import CreateLensSpace from './CreateLensSpace.vue'
import ConnectAdAccount from './ConnectAdAccount.vue'
import ConnectionSettings from './ConnectionSettings.vue'
import SetupBenchmarks from './SetupBenchmarks.vue'

export default {
  name: 'LensCreationModal',
  components: {
    CreateLensSpace,
    ConnectAdAccount,
    ConnectionSettings,
    SetupBenchmarks
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    lensForm: {
      space: {
        companyName: '',
        currency: '',
        timezone: ''
      },
      connection: {
        adAccount: '',
        goalMetric: '',
        spendThreshold: '',
        attributionWindowClickType: '',
        attributionWindowViewType: ''
      },
      benchmark: {
        selectedIndustry: '',
        selectedAOV: '',
        selectedRevenue: ''
      }
    },
    currentStep: 0,
    steps: [
      'Create a Lens Space',
      'Connect an Ad Account',
      'Connection Settings',
      'Setup Benchmarks'
    ]
  }),
  computed: {
    isLastStep () {
      return this.currentStep === this.steps.length - 1
    }
  },
  methods: {
    async handleComplete () {
      const { space, connection, benchmark } = this.lensForm

      const { min: aov_min, max: aov_max } = benchmark.selectedAOV.value
      const { min: gmv_min, max: gmv_max } = benchmark.selectedRevenue.value

      const payload = {
        name: space.companyName,
        // Space
        company_name: space.companyName,
        currency: space.currency.value,
        timezone: space.timezone.value,
        // Connection
        // ad_account: TODO @Sam: update when TW gives us info
        goal_metric: connection.goalMetric.value,
        spend_threshold: +connection.spendThreshold.value,
        click_attribution_window: connection.attributionWindowClickType.value,
        view_attribution_window: connection.attributionWindowViewType.value,
        // Benchmark
        niche: benchmark.selectedIndustry.value,
        aov_min,
        aov_max,
        // TODO @Sam: Ask about GMV requirements (range or value)
        gmv: gmv_max
      }
      this.$emit('complete', payload)
    },
    handleCancel () {
      this.$emit('close')
    },
    handleBack () {
      if (this.currentStep > 0) {
        this.currentStep--
      }
    },
    handleNext () {
      if (this.isLastStep) {
        // Handle completion
        this.handleComplete()
        this.$emit('close')
      } else {
        this.currentStep++
      }
    }
  }
}

</script>
