<template>
  <div class="p-5">
    <BaseText
      type="label"
      size="sm"
      class="text-text-muted mb-1"
    >
      Anonymous Benchmarks
    </BaseText>
    <BaseText
      type="body"
      size="sm"
      class="text-text-normal w-full"
    >
      Compare your own performance metrics to 20,000 other companies.
    </BaseText>
    <div class="border border-border-normal rounded-md flex flex-col mt-5 gap-5 p-2.5">
      <!-- Industry Dropdown -->
      <BaseSingleDropdown
        show-label-space
        label="Industry / Niche"
        description="Choose the niche that is closest to Prose."
        :options="industryOptions"
        class="z-30"
        option-key="label"
        :selected-obj="form.selectedIndustry"
        @change="updateFormField('selectedIndustry',$event)"
      />

      <!-- AOV Dropdown -->
      <BaseSingleDropdown
        show-label-space
        label="Average Order Value (AOV)"
        description="Specify the AOV of Prose to be compared to similar companies."
        :options="aovOptions"
        class="z-20"
        option-key="label"
        :selected-obj="form.selectedAOV"
        @change="updateFormField('selectedAOV',$event)"
      />

      <!-- GMV/Revenue Dropdown -->
      <BaseSingleDropdown
        show-label-space
        label="GMV/Revenue"
        description="Specify the yearly revenue range of Prose to be compared to brands of a"
        :options="revenueOptions"
        class="z-10"
        option-key="label"
        :selected-obj="form.selectedRevenue"
        @change="updateFormField('selectedRevenue',$event)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SetupBenchmarks',
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      // Options
      industryOptions: [
        { label: 'Art', value: 'art' },
        { label: 'Baby', value: 'baby' },
        { label: 'Books', value: 'books' },
        { label: 'Clothing', value: 'clothing' },
        { label: 'Electronics', value: 'electronics' },
        { label: 'Fashion Accessories', value: 'fashion_accessories' },
        { label: 'Food & Beverages', value: 'food_beverages' },
        { label: 'Health & Beauty', value: 'health_beauty' },
        { label: 'Home & Garden', value: 'home_garden' },
        { label: 'Pet Supplies', value: 'pet_supplies' },
        { label: 'Sporting Goods', value: 'sporting_goods' },
        { label: 'Toys & Hobbies', value: 'toys_hobbies' },
        { label: 'Other', value: 'other' }
      ],
      aovOptions: [
        { label: '<$100', value: { min: null, max: 99 } },
        { label: '$100 - $200', value: { min: 100, max: 200 } },
        { label: '$200 - $500', value: { min: 200, max: 500 } },
        { label: '$500 - $1000', value: { min: 500, max: 1000 } },
        { label: '>$1000', value: { min: 10001, max: null } }
      ],
      revenueOptions: [
        { label: '<$1M', value: { min: null, max: 1000000 } },
        { label: '$1M - $10M', value: { min: 1000000, max: 10000000 } },
        { label: '$10M - $50M', value: { min: 10000000, max: 50000000 } },
        { label: '$50M - $100M', value: { min: 50000000, max: 100000000 } },
        { label: '>$100M', value: { min: 100000000, max: null } }
      ]
    }
  },
  methods: {
    updateFormField (field, value) {
      this.$emit('update:form', { ...this.form, [field]: value })
    }
  }
}
</script>
