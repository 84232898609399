<template>
  <MainLayout
    bg-color="white"
    no-content-padding
  >
    <template #content>
      <LensCreationModal
        v-if="showLensCreationModal"
        @complete="handleLensCreation"
        @close="showLensCreationModal = false"
      />
      <LensEmptyState
        @createLens="showLensCreationModal = true"
      />
    </template>
  </MainLayout>
</template>

<script>
import LensAPI from '@/api/lens'
import MainLayout from '../../layouts/MainLayout.vue'
import LensEmptyState from '../../components/lens/LensEmptyState.vue'
import LensCreationModal from '../../components/lens/lensCreationModal/LensCreationModal.vue'

export default {
  name: 'LensView',
  components: {
    LensCreationModal,
    LensEmptyState,
    MainLayout
  },
  data: () => ({
    showLensCreationModal: false
  }),
  methods: {
    async handleLensCreation (payload) {
      try {
        await LensAPI.Lens.create(payload)
      } catch (e) {
        this.$showAlert({ type: 'error', message: e.message })
      }
    }
  }
}
</script>
