<template>
  <div class="p-5">
    <div class="border border-border-normal rounded-md flex flex-col gap-5 p-2.5">
      <!-- Ad Account -->
      <BaseSingleDropdown
        show-label-space
        label="Ad Account"
        :options="adAccounts"
        class="z-30"
        option-key="label"
        :selected-obj="form.adAccount"
        @change="updateFormField('adAccount', $event)"
      />

      <!-- Goal Metric -->
      <BaseSingleDropdown
        show-label-space
        label="Goal Metric"
        description="Choose a default metric to enable relevant insights to your goals."
        :options="goalMetrics"
        class="z-20"
        option-key="label"
        :selected-obj="form.goalMetric"
        @change="updateFormField('goalMetric', $event)"
      />

      <!-- Spend Threshold -->
      <div>
        <BaseText
          type="label"
          size="sm"
          class="text-text-muted mb-1"
        >
          Spend Threshold
        </BaseText>
        <BaseText
          type="body"
          size="sm"
          class="text-text-normal mb-1 w-full"
        >
          Minimum amount a creative must spend for its results to have statistical significance.
        </BaseText>
        <BaseInputV2
          :value="form.spendThreshold"
          description="Minimum amount a creative must spend for its results to have statistical significance."
          placeholder="Enter spend threshold"
          @input="updateFormField('spendThreshold', $event)"
        />
      </div>

      <!-- Attribution Window -->
      <div>
        <BaseText
          type="label"
          size="sm"
          class="text-text-muted mb-1"
        >
          Attribution Window
        </BaseText>
        <BaseText
          type="body"
          size="sm"
          class="text-text-normal mb-1 w-full"
        >
          The time frame in which a user action can be credited for a conversion. Helps track the effectiveness of your campaigns.
        </BaseText>
        <div class="flex items-center gap-2">
          <BaseSingleDropdown
            :options="clickAttributionWindows"
            class="z-10 flex-1"
            option-key="label"
            :selected-obj="form.attributionWindowClickType"
            placeholder="Click"
            @change="updateFormField('attributionWindowClickType', $event)"
          />
          <BaseSingleDropdown
            :options="viewAttributionWindows"
            class="z-10 flex-1"
            option-key="label"
            :selected-obj="form.attributionWindowViewType"
            placeholder="View"
            @change="updateFormField('attributionWindowViewType', $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConnectionSettings',
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    // Option Data
    adAccounts: [], // This will need to be populated with your ad account data
    goalMetrics: [],
    clickAttributionWindows: [
      { label: '1 Day Click', value: '1d' },
      { label: '7 Days Click', value: '7d' },
      { label: '28 Days Click', value: '28d' }
    ],
    viewAttributionWindows: [
      { label: '1 Day View', value: '1d' },
      { label: '7 Days View', value: '7d' },
      { label: '28 Days View', value: '28d' }
    ]
  }),
  created () {
    // Transform ad account data (example structure - adjust according to your data source)
    this.adAccounts = [
      { label: 'Account 1', value: 'account1' },
      { label: 'Account 2', value: 'account2' }
    ]

    // Add goal metrics data
    this.goalMetrics = [
      { label: 'Conversions', value: 'conversions' },
      { label: 'Revenue', value: 'revenue' },
      { label: 'ROAS', value: 'roas' },
      { label: 'CPA', value: 'cpa' }
    ]
  },
  methods: {
    updateFormField (field, value) {
      this.$emit('update:form', { ...this.form, [field]: value })
    }
  }
}
</script>
