<template>
  <div class="p-5">
    <!-- Top Container -->
    <div v-if="!iframeUrl">
      <div
        class="h-44 relative bg-opacity-5 flex items-center justify-center rounded-md rounded-b-none border border-b-0 border-border-normal"
        style="background: rgba(56,127,243,0.05);"
      >
        <div class="dot-mask" />

        <!-- Connect Meta Button -->
        <BaseButton
          class="mt-5 absolute"
          outlined
          :loading="loading"
          @click="handleConnectMeta"
        >
          <div class="flex justify-between items-center gap-1.5">
            <MetaPlatformIcon />
            <BaseText
              class="text-text-muted"
              type="label"
              size="sm"
            >
              Connect to Meta
            </BaseText>
          </div>
        </BaseButton>
      </div>

      <div class="flex items-center justify-between p-2.5 mb-5 border border-border-normal rounded-md rounded-t-none">
        <!-- Left Side -->
        <BaseText
          type="body"
          size="xs"
          class="text-text-normal"
        >
          Coming Soon
        </BaseText>

        <!-- Right Side -->
        <div class="flex gap-2.5">
          <!-- TikTok -->
          <div class="p-1.5 border border-border-normal rounded-md">
            <img>
          </div>
          <!-- Google Ads -->
          <div class="p-1.5 border border-border-normal rounded-md">
            <img>
          </div>
          <!-- LinkedIn -->
          <div class="p-1.5 border border-border-normal rounded-md">
            <img>
          </div>
          <!-- Snapchat -->
          <div class="p-1.5 border border-border-normal rounded-md">
            <img>
          </div>
          <!-- X -->
          <div class="p-1.5 border border-border-normal rounded-md">
            <img>
          </div>
          <!-- Pinterest -->
          <div class="p-1.5 border border-border-normal rounded-md">
            <img>
          </div>
          <!-- App Lovin -->
          <div class="p-1.5 border border-border-normal rounded-md">
            <img>
          </div>
        </div>
      </div>

      <!-- Data Pipelin -->
      <div class="bg-background-normal rounded-md p-2.5">
        <div class="flex items-center justify-between pb-1">
          <BaseText
            type="label"
            size="sm"
          >
            Data Pipelines Powered by
          </BaseText>

          <!-- TW image -->
          <img src="../../../assets/images/tripleWhaleLogo.svg">
        </div>

        <!-- BaseText -->
        <BaseText
          type="body"
          size="xs"
          class="text-text-normal"
        >
          You see a pop-up to connect your ad account with Triple Whale Data pipelines.
        </BaseText>
        <BaseText
          type="body"
          size="xs"
          class="text-text-muted"
        >
          You DO NOT need a Triple Whale account to do this.
        </BaseText>
      </div>
    </div>

    <!-- iframe Container -->
    <div
      v-if="iframeUrl"
      class="text-center p-4"
    >
      <BaseText
        type="body"
        size="sm"
        class="text-text-muted mb-3"
      >
        Authentication window opened in a new tab
      </BaseText>
      <BaseButton
        outlined
        @click="openAuthWindow"
      >
        Reopen Authentication Window
      </BaseButton>
    </div>
  </div>
</template>
<script>
import MetaPlatformIcon from '../../globals/Icons/FilterIcons/Platforms/MetaPlatformIcon.vue'
import LensAPI from '@/api/lens'

export default {
  name: 'ConnectAdAccount',
  components: {
    MetaPlatformIcon
  },
  data () {
    return {
      iframeUrl: null,
      loading: false,
      authWindow: null
    }
  },
  methods: {
    async handleConnectMeta () {
      try {
        this.loading = true

        const registeredTWAccounts = await LensAPI.Lens.getTWAccounts()
        const incompleteSignup = registeredTWAccounts.data.find(account => !account.access_token)

        if (incompleteSignup) {
          const { url, internal_tw_account_id: twAccountId } = await LensAPI.Lens.getTWRedirect(incompleteSignup.internal_tw_account_id)
          this.url = url
          this.twAccountId = twAccountId
        } else {
          // Create an account on TW
          const { url, internal_tw_account_id: twAccountId } = await LensAPI.Lens.createTWAccount()
          this.url = url
          this.twAccountId = twAccountId
        }

        this.iframeUrl = this.url
        this.openAuthWindow()
      } catch (error) {
        console.error('Failed to get Meta connection URL:', error)
        // Handle error appropriately
      } finally {
        this.loading = false
      }
    },
    openAuthWindow () {
      // Close previous window if it exists
      if (this.authWindow && !this.authWindow.closed) {
        this.authWindow.close()
      }
      // Open new window
      this.authWindow = window.open(
        this.iframeUrl,
        'MetaAuth',
        'width=600,height=600,left=200,top=200'
      )
    }
  }
}
</script>
<style>
.dot-mask {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  background-image: radial-gradient(#e5e7eb 1px, transparent 1px);
  background-size: 16px 16px;
}
</style>
