<template>
  <div class="p-5 flex flex-col  gap-5">
    <!-- Company Name -->
    <BaseInputV2
      :value="form.companyName"
      label="Company Name"
      placeholder="Enter your company name"
      @input="updateFormField('companyName', $event)"
    />
    <!-- Currency -->
    <BaseSingleDropdown
      show-label-space
      label="Currency"
      :options="currencies"
      class="z-10"
      option-key="label"
      :selected-obj="form.currency"
      @change="updateFormField('currency', $event)"
    />
    <!-- Reporting Timezone -->
    <BaseSingleDropdown
      show-label-space
      label="Reporting Timezone"
      option-key="label"
      :options="timeZones"
      :selected-obj="form.timezone"
      @change="updateFormField('timezone', $event)"
    />
  </div>
</template>
<script>
import timeZonesData from './dropdownData/IANATimeZones.json'
import currenciesData from './dropdownData/ISOCurrecies.json'

export default {
  name: 'CreateLensSpace',
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    // Option Data
    currencies: [],
    timeZones: []
  }),
  created () {
    // Transform timezone data
    this.timeZones = Object.entries(timeZonesData).map(([zone, offset]) => ({
      label: `${zone} (UTC${offset})`,
      value: zone
    }))

    // Transform currency data
    this.currencies = Object.entries(currenciesData)
      .filter(([_, data]) => data.ISOnum !== null) // Filter out non-ISO currencies
      .map(([code, data]) => ({
        label: `${data.name} (${code})`,
        value: code
      }))
  },
  methods: {
    updateFormField (field, value) {
      this.$emit('update:form', { ...this.form, [field]: value })
    }
  }
}
</script>
